import React, { useEffect, useState } from 'react'
import { Field, reduxForm } from 'redux-form'
import { useTheme, withStyles } from '@material-ui/core/styles';
import { TextField, Button, CircularProgress, Avatar, Checkbox, Box, Typography, useMediaQuery, FormControlLabel } from "@material-ui/core";
import './style.css'
import firebaseUtil from '../../../../../firebaseUtil'
import useNotification from '../../../../../notification';

import { useTranslation } from 'react-i18next';
import useAuth from '../../../../../useAuth';
import LoginIntegrado from 'routes/public/components/LoginIntegrado';
import { Link } from 'react-router-dom';
import { getOkFormSnap } from 'OkApp/OkFunctions';

import registerImg from "./register.png"
import { LogTracker } from 'OkApp/OkTracker';

const renderTextField = ({ input, label, meta: { touched, error }, ...custom }) => (
    <TextField
        label={label}
        error={(touched && error) ? true : false}
        helperText={(touched && error) ? error : ""}
        fullWidth

        {...input}
        {...custom}
    />
)

const renderCheckboxValidator = ({ input, setAccept, accept, meta: { touched, error }, ...custom }) => {
    return (
        <>
            <FormControlLabel
                control={<Checkbox
                    name="accept"
                    id='terms'
                    // checked={accept}
                    onChange={(p, value) => {
                        setAccept(value)
                    }}
                    color="primary"
                    {...input}
                    {...custom}
                />}
                label={
                    <>
                        Li e Aceito os <Link href="/TermosUso" target="_blank">Termos de Uso</Link>
                    </>
                }
            />

            {error && <div style={{ color: 'red' }}>Aceite os termos para continuar.</div>}
        </>
    )
}

function Register(props) {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();
    const { showSuccess, showWarning } = useNotification()
    const [accept, setAccept] = useState(false);
    const { handleSubmit, submitting, okApp, pagePosLogin, valid } = props
    const { notAuthenticatedOnly, redirectPosAuth, redirectLogin, registerSingIn } = useAuth();
    const [pagePosLoginST, setPagePosLoginST] = useState(pagePosLogin)
    notAuthenticatedOnly();

    useEffect(() => {
        let observer = null
        if (okApp && pagePosLoginST === "/app/" + okApp.route) {
            if (okApp.formInicio) {
                observer = getOkFormSnap(okApp, okApp.formInicio.uid,
                    (r) => {
                        if (r) {
                            setPagePosLoginST("/app/" + okApp.route + "/" + r.route)
                        }
                    },
                    (err) => {
                    }
                );
            }
        }
        return () => {
            if (observer) observer()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [okApp])
    async function registrarUsuario(dados) {
        try { 
            await firebaseUtil.register(dados.name, dados.email, dados.password)

            let dataToSend = {
                name: dados.name,
                email: dados.email,
                created_at: new Date()
            }

            LogTracker("OkApp - Registro comum", dataToSend, null)
            registerSingIn();
            showSuccess(t('sucessRegister'))
            redirectPosAuth(pagePosLoginST)
        } catch (error) {
            if (error.code === "auth/email-already-in-use") {
                showWarning("E-mail já está em uso")
            }
            console.log("error.message", error.code);
        }
    }

    return (
        <Box display="flex" alignItems="center" justifyContent="center" height="100vh" width="100%" style={{ background: "linear-gradient(to bottom, #575fff83,  #ef9c3283)" }}>
            <Box display="flex" maxWidth={mobile ? "95%" : 850} flexDirection={mobile ? "column" : "row"} borderRadius={15} style={{ background: mobile ? "#eeeeee95" : "#eee", boxShadow: "0px 0px 11px 0px rgba(0,0,0,0.54)" }} height="fit-content">
                <Box padding={3} display="flex" flexDirection="column" width={mobile ? "100%" : "50%"}>
                    <Typography style={{ textAlign: 'center', color: "rgb(82, 82, 82)", marginBottom: 20, fontWeight: 'bold', fontSize: 18, fontStyle: 'italic' }}>
                        Faça seu cadastro
                    </Typography>
                    <Box marginBottom={2}>
                        <Field name="name" variant="outlined" valid={valid} handleSubmit={handleSubmit} component={renderTextField} label={t('user.name')} />
                    </Box>
                    <Box marginBottom={2}>
                        <Field name="email" variant="outlined" valid={valid} handleSubmit={handleSubmit} component={renderTextField} label={t('user.email')} />
                    </Box>
                    <Box marginBottom={2}>
                        <Field name="password" variant="outlined" valid={valid} handleSubmit={handleSubmit} component={renderTextField} label={t('user.password')} type="password" />
                    </Box>
                    <Box marginBottom={2}>
                        <Field name="passwordRepeat" variant="outlined" valid={valid} handleSubmit={handleSubmit} component={renderTextField} label={t('user.passwordRepeat')} type="password" />
                    </Box>
                    <Field name='accept' valid={valid} handleSubmit={handleSubmit} component={renderCheckboxValidator} setAccept={setAccept} accept={accept} />
                    <Box style={{ paddingTop: 15 }} >
                        <Button variant="contained" color="primary" fullWidth onClick={handleSubmit(registrarUsuario)} disabled={submitting}>
                            {!submitting && t('auth.register')}
                            {submitting && <CircularProgress size={25} />}
                        </Button>
                    </Box>
                    <Box display="flex" alignItems='center' justifyContent='center' style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <Typography style={{ fontStyle: "italic", color: "rgb(82, 82, 82)" }}>ou</Typography>
                    </Box>
                    <LoginIntegrado pagePosLogin={pagePosLoginST} />

                    <Typography style={{ fontSize: 12, cursor: "pointer", textDecoration: "underline", marginTop: 15, textAlign: 'center', color: theme.palette.primary.light }} onClick={() => redirectLogin(okApp)}>Já tenho uma conta</Typography>

                </Box>
                {!mobile &&
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{ width: "50%", background: 'lightgray', borderBottomRightRadius: 15, borderTopRightRadius: 15 }} padding={5}>
                        <Avatar variant={'rounded'} alt={'logo'} style={{ width: "300px", height: "300px" }} src={registerImg} />
                    </Box>
                }
            </Box>
        </Box>
    )
}

const validate = values => {
    const errors = {}
    if (!values.name) {
        errors.name = 'Requerido'
    }
    if (!values.email) {
        errors.email = 'Requerido'
    }
    // else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2, 4}$/i.test(values.email)) {
    //     errors.email = 'Email inválido'
    // }
    if (!values.password) {
        errors.password = 'Requerido'
    }
    if (values.accept === false) {
        errors.accept = 'Aceite os termos'
    }
    else if (values.password !== values.passwordRepeat) {
        errors.passwordRepeat = 'Senhas não conferem'
    }
    return errors
}

export default reduxForm({
    validate,
    form: 'RegisterUser'
})(withStyles({})(Register))