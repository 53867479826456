import firebaseUtil from "./firebaseUtil";
import {
  collection,
  getDocs,
  doc,
  setDoc,
  getDoc,
  onSnapshot,
  where,
  query,
  limit,
  serverTimestamp,
  runTransaction,
  writeBatch,
  arrayUnion,
  updateDoc,
} from "firebase/firestore";
import QueryFactory from "OkApp/QueryFactory";

export function salvarObj(obj, strCollection, resolve, reject, salvarTambem) {
  salvarObjDatabase(
    firebaseUtil.db,
    obj,
    strCollection,
    resolve,
    reject,
    salvarTambem
  );
}
export function deleteObj(obj, strCollection, resolve, reject, salvarTambem) {
  deleteObjDatabase(
    firebaseUtil.db,
    obj,
    strCollection,
    resolve,
    reject,
    salvarTambem
  );
}
export function salvarObjUidApp(
  uidOkAPP,
  obj,
  strCollection,
  resolve,
  reject,
  salvarTambem
) {
  salvarObjDatabase(
    firebaseUtil.getDataBaseOkApp(uidOkAPP),
    obj,
    strCollection,
    resolve,
    reject,
    salvarTambem
  );
}
export function deleteObjUidApp(
  uidOkAPP,
  obj,
  strCollection,
  resolve,
  reject,
  salvarTambem
) {
  deleteObjDatabase(
    firebaseUtil.getDataBaseOkApp(uidOkAPP),
    obj,
    strCollection,
    resolve,
    reject,
    salvarTambem
  );
}

export function salvarObjDatabase(
  dataBase,
  obj,
  strCollection,
  resolve,
  reject,
  salvarTambem
) {
  if (!dataBase) {
    dataBase = firebaseUtil.db;
  }

  // let colection = dataBase.collection(strCollection);
  let colection = collection(dataBase, strCollection);
  let docSalvar = getDocSalvarAjustaObj(obj, colection);
  let syncKey = "syncKey" + new Date().getTime();
  obj = { ...obj, syncKey };
  let salvouOnline = false;

  let observer = onSnapshot(
    docSalvar,
    (docSnapshot) => {
      if (docSnapshot.data()) {
        if (syncKey === docSnapshot.data().syncKey) {
          if (observer) {
            observer();
            observer = null;
          }
          if (!salvouOnline)
            setTimeout(() => {
              if (!salvouOnline) {
                if (resolve)
                  resolve({ msg: "Salvou localmente.", obj, docSalvar });
              }
            }, 2000);
        }
      }
    },
    (err) => {
      console.log("Encountered error:", err);
    }
  );
  //Salvar Aqui

  // let batch = firebase.db.batch();

  runTransaction(dataBase, async (t) => {
    let refDocSeq = doc(
      dataBase,
      "SequencialControl",
      "DocSequecialControl",
      strCollection,
      "DocSequecialControl"
    );
    // let refDocSeq = dataBase.collection("SequencialControl").doc("DocSequecialControl").collection(strCollection).doc("DocSequecialControl")
    // console.log('Ref doc ', refDocSeq);
    return t
      .get(refDocSeq)
      .then((doc) => {
        let docSeq = doc.data();
        let sequencial = 1;

        if (docSeq && docSeq.sequencial) {
          if (!obj.sequencialIndex) {
            sequencial = docSeq.sequencial + 1;
            obj.sequencialIndex = sequencial;
          } else {
            sequencial = docSeq.sequencial;
          }
        } else {
          if (!obj.sequencialIndex) {
            obj.sequencialIndex = sequencial;
          } else {
            if (obj.sequencialIndex > sequencial) {
              sequencial = obj.sequencialIndex;
            }
          }
        }

        addDocumentoPermissoes(obj);
        // console.log('salvando', obj)
        t.set(docSalvar, obj, { merge: true });

        if (salvarTambem) {
          for (let index = 0; index < salvarTambem.length; index++) {
            const element = salvarTambem[index];
            if (element.obj)
              salvaEmTransactionInternos(
                dataBase,
                element.obj,
                element.strCollection,
                t
              );
          }
        }

        t.set(refDocSeq, { sequencial: sequencial }, { merge: true });
      })
      .catch((err) => {
        if (observer) {
          observer();
          observer = null;
        }
      });
  })
    .then((docRef) => {
      salvouOnline = true;
      if (resolve) resolve({ msg: "Salvou online", obj, docSalvar });
      if (observer) {
        observer();
        observer = null;
      }
    })
    .catch((error) => {
      console.log("catch docSalvar error:", error);
      console.log("catch strCollection:", strCollection);
      console.log("catch docSalvar:", docSalvar);
      console.log("catch obj:", obj);

      reject(error);
      if (observer) {
        observer();
        observer = null;
      }
    });

  setTimeout(() => {
    if (observer) {
      observer();
      console.log("Time out observer do okapp");
      // alert("Time out observer");
    }
  }, 10000);
}

export function deleteObjDatabase(
  dataBase,
  obj,
  strCollection,
  resolve,
  reject,
  deletaTambem
) {
  if (!dataBase) {
    dataBase = firebaseUtil.db;
  }
  // let colection = collection(dataBase, strCollection);
  let docDelete = doc(dataBase, strCollection, obj.uid);

  let docsDeltar = [docDelete];
  if (deletaTambem) {
    for (let index = 0; index < deletaTambem.length; index++) {
      const element = deletaTambem[index];
      if (element.obj) {
        docsDeltar.push(doc(dataBase, element.strCollection, element.obj.uid));
      }
    }
  }
  // let batch = dataBase.batch();
  let batch = writeBatch(dataBase);
  docsDeltar.forEach((doc) => {
    batch.delete(doc);
  });

  batch
    .commit()
    .then(() => {
      if (resolve) resolve({ msg: "Registro excluido!" });
    })
    .catch((err) => {
      console.log("catch deletar docs:", err);
      reject(err);
    });
}

export function saveOnlyNew(obj, colectionStr, ifExistOnlyUpdateData) {
  let colection = collection(firebaseUtil.db, colectionStr);

  const { uid } = obj;
  let docSalvar = null;
  if (uid) {
    docSalvar = doc(colection, uid);
  } else {
    docSalvar = doc(colection);
    obj.uid = docSalvar.id;
  }

  obj.lastChange = serverTimestamp();
  if (ifExistOnlyUpdateData) {
    getDoc(docSalvar).then((docSnapshot) => {
      if (docSnapshot.exists()) {
        let dataUpdate = {
          uid,
          lastChange: serverTimestamp(),
          ...ifExistOnlyUpdateData,
        };
        updateDoc(docSalvar, dataUpdate);
        // setDoc(docSalvar, dataUpdate, { merge: true })
      } else {
        setDoc(docSalvar, obj, { merge: true });
      }
    });
  } else {
    setDoc(docSalvar, obj, { merge: true });
  }
}
function getDocSalvarAjustaObj(obj, colection) {
  const { uid } = obj;
  let docSalvar = null;
  if (uid) {
    docSalvar = doc(colection, uid);
  } else {
    docSalvar = doc(colection);
    obj.uid = docSalvar.id;
  }
  if (firebaseUtil.getCurrentUser()) {
    obj.lastChangeUser = firebaseUtil.getCurrentUser().uid;
  } else {
    obj.lastChangeUser = "undefined";
  }
  obj.lastChange = serverTimestamp();
  addDocumentoPermissoes(obj);
  return docSalvar;
}

function addDocumentoPermissoes(objSave) {
  let obj = objSave.metaOkApp ? objSave.metaOkApp.permission : {};
  if (!objSave.metaOkApp) {
    objSave.metaOkApp = {};
  }
  if (!obj) {
    obj = {};
  }
  if (!obj.owner) {
    if (firebaseUtil.getCurrentUser()) {
      obj.owner = firebaseUtil.getCurrentUser().uid;
    } else {
      obj.owner = "undefined";
    }
  }
  if (!obj.usersWrite) {
    obj.usersWrite = {};
    // obj.usersWrite[firebase.getCurrentUser().uid] = true
  }
  if (!obj.usersRead) {
    obj.usersRead = {};
    // obj.usersRead[firebase.getCurrentUser().uid] = true
  }
  if (obj.public === undefined) {
    if (firebaseUtil.getCurrentUser()) {
      obj.public = false;
    } else {
      obj.public = true;
    }
  }
  //update PermiDataBase
  let permiQuery = [];
  permiQuery.push(obj.owner);
  for (const key in obj.usersWrite) {
    if (obj.usersWrite.hasOwnProperty(key)) {
      const p = obj.usersWrite[key];
      if (p) {
        if (!permiQuery.includes(key)) permiQuery.push(key);
      }
    }
  }
  if (obj.public) {
    permiQuery.push("PUBLIC");
  }
  for (const key in obj.usersRead) {
    if (obj.usersRead.hasOwnProperty(key)) {
      const p = obj.usersRead[key];
      if (p) {
        if (!permiQuery.includes(key)) permiQuery.push(key);
      }
    }
  }
  obj.queryPermission = permiQuery;
  objSave.metaOkApp.permission = obj;
}

function salvaEmTransactionInternos(dataBase, obj, strCollection, batch) {
  let colection = collection(dataBase, strCollection);
  let docSalvar = getDocSalvarAjustaObj(obj, colection);
  batch.set(docSalvar, obj, { merge: true });
}
export function salvarPerfilRegistro(UserFirebase, add) {
//   console.log("salvarPerfilRegistro UserFirebase", UserFirebase);
  let obj = {
    uid: UserFirebase.uid,
    email: UserFirebase.email,
    emailVerified: UserFirebase.emailVerified,
  };
  if (UserFirebase.displayName) {
    obj.name = UserFirebase.displayName;
  }

  if (add) {
    obj = { ...obj, ...add };
  }
  // salvarObj(obj, "Users")
  saveOnlyNew(obj, "Users");
}
export function updatePerfilUid(uidPerfil, add) {
  let obj = { uid: uidPerfil };

  if (add) {
    obj = { ...obj, ...add };
  }
  // salvarObj(obj, "Users")
  saveOnlyNew(obj, "Users");
}
export function updatePerfil(UserFirebase, add) {
//   console.log("UserFirebase", UserFirebase);
  let obj = {
    uid: UserFirebase.uid,
    email: UserFirebase.email,
    emailVerified: UserFirebase.emailVerified,
  };

  if (UserFirebase.displayName) {
    obj.name = UserFirebase.displayName;
  } else if (UserFirebase.name) {
    obj.name = UserFirebase.name;
  }

  if (add) {
    obj = { ...obj, ...add };
  }
  // salvarObj(obj, "Users")
  saveOnlyNew(obj, "Users");
}

export function salvarPerfilLogin(UserFirebase, okApp) {
//   console.log("salvarPerfilLogin UserFirebase", UserFirebase);

  let obj = {
    uid: UserFirebase.uid,

    email: UserFirebase.email,
    emailVerified: UserFirebase.emailVerified,
  };

  if (UserFirebase.displayName) {
    obj.name = UserFirebase.displayName;
  }
  obj.firstLogin = serverTimestamp();
  if (okApp) {
    let update = { lastLogin: serverTimestamp(), lastOkAppRoute: okApp.route };
    update["mapLastOkAppRoute." + okApp.route] = serverTimestamp();

    saveOnlyNew(obj, "Users", update);

    saveOnlyNew(obj, "Project/" + okApp.uid + "/Users", {
      lastLogin: serverTimestamp(),
    });

    if (okApp.formPerfil) {
      //inicia salva perfis que já abriram o app.
      obj.metaOkApp = {
        permission: { public: true, queryPermission: ["PUBLIC"] },
      };

      saveOnlyNew(
        obj,
        "Project/" +
          okApp.uid +
          "/ValuesCustomForm/" +
          okApp.formPerfil.uid +
          "/Values",
        { lastLogin: serverTimestamp() }
      );
    }
  } else {
    saveOnlyNew(obj, "Users", { lastLogin: serverTimestamp() });
  }
}

export function resalvaPerfislLogin(okApp) {
  if (okApp && okApp.formPerfil) {
    //busca users default
    createGetCollectionSemPermissao("Project/" + okApp.uid + "/Users")
      .get()
      .then((users) => {
        //salva users no perfil
        users.forEach((userRef) => {
          let user = userRef.data();
          user.metaOkApp = {
            permission: { public: true, queryPermission: ["PUBLIC"] },
          };
          saveOnlyNew(
            user,
            "Project/" +
              okApp.uid +
              "/ValuesCustomForm/" +
              okApp.formPerfil.uid +
              "/Values",
            {}
          );
        });
      });
  }
}
//#endregion

//#region - createGetCollection
export function createGetCollection(strCollection, compare, limite) {
  let colStart = collection(firebaseUtil.db, strCollection);
  if (compare) {
    for (var param in compare) {
      colStart = query(colStart, where(param, "==", compare[param]));
    }
  }

  colStart = permissionFilter(colStart);

  return colStart;
}
export function createGetCollectionSemPermissao(strCollection) {
  const colStart = new QueryFactory(null, strCollection);
  // new Qu
  // as
  // let colStart = collection(firebaseUtil.db, strCollection)

  return colStart;
}

//#endregion

function permissionFilter(col) {
  let ArrayPermissao = ["PUBLIC"];

  if (firebaseUtil.getCurrentUser()) {
    ArrayPermissao.push(firebaseUtil.getCurrentUser().uid);
  }
  col = query(
    col,
    where(
      "metaOkApp.permission.queryPermission",
      "array-contains-any",
      ArrayPermissao
    )
  );
  return col;
}

//#region - createSnapCollection
export function createSnapCollection(
  strCollection,
  resolve,
  reject,
  filterCompare,
  limite,
  startAfter
) {
  return createSnapCollectionDatabase(
    firebaseUtil.db,
    strCollection,
    resolve,
    reject,
    filterCompare,
    limite,
    startAfter
  );
}
export function createSnapCollectionDatabase(
  database,
  strCollection,
  resolve,
  reject,
  filterCompare,
  limite,
  startAfter
) {
  if (!database) database = firebaseUtil.db;
  let col = getCollectionEquals(database, strCollection, filterCompare);
  if (!col) {
    return null;
  }
  col = permissionFilter(col);

  // col = col.orderBy("uid")

  if (startAfter) {
    col = col.startAfter(startAfter);
  }

  if (limite) {
    col = query(col, limit(limite));
  }
  // else {
  //     col = col.limit(1000);
  // }

  let observer = onSnapshot(
    col,
    (querySnapshot) => {
      if (querySnapshot.empty) {
        resolve([]);
      } else {
        let r = [];
        querySnapshot.forEach((doc) => {
          r.push(doc.data());
        });
        resolve(r);
      }
    },
    (err) => {
      console.log(`createSnapCollection error: ${err}`);
      reject(err);
    }
  );
  return observer;
}

export function createSnapCollectionSemPermissao(
  strCollection,
  resolve,
  reject,
  filterCompare,
  limite,
  startAfter
) {
  return createSnapCollectionSemPermissaoDatabase(
    firebaseUtil.db,
    strCollection,
    resolve,
    reject,
    filterCompare,
    limite,
    startAfter
  );
}
export function createSnapCollectionSemPermissaoDatabase(
  database,
  strCollection,
  resolve,
  reject,
  filterCompare,
  limite,
  startAfter
) {
  if (!database) database = firebaseUtil.db;

  let col = getCollectionEquals(database, strCollection, filterCompare);

  // col = col.orderBy("uid")

  if (limite) {
    // col = query(col, limit(limite));
    col = query(col, limit(limite));
  }
  // else {
  //     col = col.limit(1000);
  // }
  if (startAfter) {
    col = col.startAfter(startAfter);
  }
  let observer = onSnapshot(
    col,
    (querySnapshot) => {
      if (querySnapshot.empty) {
        resolve([]);
      } else {
        let r = [];
        querySnapshot.forEach((doc) => {
          r.push(doc.data());
        });

        resolve(r);
      }
    },
    (err) => {
      console.log(`createSnapCollection error: ${err}`);
      reject(err);
    }
  );
  return observer;
}
//#endregion

//#region - crateSnapDocument (nao consideraPermissao)
export function crateSnapDocument(
  uid,
  strCollection,
  resolve,
  reject,
  uidUser
) {
  return crateSnapDocumentDatabase(
    firebaseUtil.db,
    uid,
    strCollection,
    resolve,
    reject,
    uidUser
  );
}
export function crateSnapDocumentDatabase(
  database,
  uid,
  strCollection,
  resolve,
  reject,
  uidUser
) {
  if (!database) database = firebaseUtil.db;
  // console.log("buscaSyncCollection", strCollection);
  let coll = collection(database, strCollection);
  let docSalvar = doc(coll, uid);
  let observer = onSnapshot(
    docSalvar,
    (docSnapshot) => {
      // console.log('docSnapshot',docSnapshot)
      if (docSnapshot.exists()) {
        resolve(docSnapshot.data());
      } else {
        reject({ message: "not fond", code: 404 });
      }
    },
    (err) => {
      console.log("buscaSyncObj error:", err);
      reject(err);
    }
  );
  // console.log('observer',observer)
  return observer;
}

//#endregion

//#region - Util
function getCollectionEquals(dataBase, strCollection, compare) {
  let col = collection(dataBase, strCollection);
  if (compare) {
    if (isFunction(compare)) {
      col = compare(col);
    } else {
      for (var param in compare) {
        let valueCompare = compare[param];

        try {
          if (typeof valueCompare === "string" && valueCompare.endsWith("%")) {
            valueCompare = valueCompare.substring(0, valueCompare.length - 1);
            const end = valueCompare.replace(/.$/, (c) =>
              String.fromCharCode(c.charCodeAt(0) + 1)
            );
            // col = col.where(param, '>=', valueCompare).where(param, '<', end);

            col = query(
              col,
              where(param, ">=", valueCompare),
              where(param, "<", end)
            );
          } else {
            // col = col.where(param, '==', valueCompare)
            col = query(col, where(param, "==", valueCompare));
          }
        } catch (error) {
          console.log("ERRO getCollectionEquals", error);
          console.log("param", param);
          console.log("valueCompare", valueCompare);
        }
      }
    }
  }
  return col;
}

function isFunction(object) {
  return typeof object == "function";
}
//#endregion

//#region - get App por rota
export function getListOkApps(rota) {
  let col = collection(firebaseUtil.db, "Project");
  // col = col.where("route", '==', rota);
  col = query(col, where("route", "==", rota));
  col = permissionFilter(col);

  return getDocs(col);
}

export function getListOkAppsRoutes(rota) {
  let col = collection(firebaseUtil.db, "Routes");
  // col = col.where("route", '==', rota);
  col = query(col, where("route", "==", rota));
  // col = permissionFilter(col);

  return getDocs(col);
}
//#endregion

export function serverTimestampFirestore() {
  return serverTimestamp();
}

export function createSnapCollectionProps(
  database,
  strCollection,
  resolve,
  reject,
  props
) {
  if (!database) database = firebaseUtil.db;
  const {
    collectionFilter,
    limite,
    startAfter,
    verificaPermissao,
    orderBy,
    orderByDirection,
  } = props || {};
  let col = getCollectionEquals(database, strCollection, collectionFilter);
  if (!col) {
    return null;
  }
  if (limite) {
    col = query(col, limit(limite));
  } else {
    col = col.limit(100);
  }

  if (startAfter) {
    col = col.startAfter(startAfter);
  }
  if (verificaPermissao) {
    col = permissionFilter(col);
  }
  if (orderBy) {
    if (orderByDirection) {
      col = col.orderBy(orderBy, orderByDirection);
    } else {
      col = col.orderBy(orderBy);
    }
  }

  let observer = onSnapshot(
    col,
    (querySnapshot) => {
      if (querySnapshot.empty) {
        resolve([]);
      } else {
        let r = [];
        querySnapshot.forEach((doc) => {
          r.push(doc.data());
        });
        resolve(r);
      }
    },
    (err) => {
      console.log(`createSnapCollectionProps error: ${err}`);
      reject(err);
    }
  );
  return observer;
}

export function createGetCollectionProps(
  database,
  strCollection,
  resolve,
  reject,
  props
) {
  const { collectionFilter, limite, startAfter, verificaPermissao } =
    props || {};
  let col = getCollectionEquals(database, strCollection, collectionFilter);
  if (!col) {
    return null;
  }
  if (limite) {
    if (limite !== -1) col = query(col, limit(limite));
  }
  //  else {
  //     col = col.limit(1000);
  // }

  if (startAfter) {
    col = col.startAfter(startAfter);
  }
  if (verificaPermissao) {
    col = permissionFilter(col);
  }

  // let observer = col.onSnapshot(querySnapshot => {
  //     if (querySnapshot.empty) {
  //         resolve([])
  //     } else {
  //         let r = []
  //         querySnapshot.forEach(doc => {
  //             r.push(doc.data());
  //         });
  //         resolve(r)
  //     }

  // }, err => {
  //     console.log(`createSnapCollection error: ${err}`);
  //     reject(err)
  // });

  getDocs(col.get)
    .then((snapshot) => {
      let ret = [];
      snapshot.forEach((doc) => {
        ret.push(doc.data());
      });
      resolve(ret);
    })
    .catch((err) => {
      console.log(`createGetCollectionProps error: ${err}`);
      reject(err);
    });
}
